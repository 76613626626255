import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import { Button, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import './ResourceEditor.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveEmployerResource, fetchEmployerResource } from '../../Utils/apiUtils';
import CustomAlert from '../Elements/CustomAlert';

const Font = ReactQuill.Quill.import('formats/font'); // <<<< ReactQuill exports it
Font.whitelist = ['roboto', 'lateef',
  // 'open'
]; // allow ONLY these fonts and the default
ReactQuill.Quill.register(Font, true);

function ResourceEditor() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const editorRef: any = useRef()
  const location: any = useLocation();
  const [alert, setAlert] = useState<any>({});

  const modules = {
    toolbar: [
      [{
        'font': ['roboto', 'lateef',
          // 'open'
        ]
      }],
      [{ 'header': [1, 2, false] }],
      [{ 'color': ['#525252', '#007DBC', '#000000'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link'
        // , 'image', 'video'
      ],
      // ['clean']
    ],
  }

  const formats = [
    'font',
    'header',
    'color',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link',
    //  'image', 'video'
  ]
  useEffect(() => {
    fetchEmployerResource(location.state.id).then((res: any) => {
      if (res.type === "success") {
        setValue(res.resource)
      }
    })
    // let editorValue=`<h1>Hi</h1><h2>Hello</h2><p>this is <a href="http://www.link.com/" rel="noopener noreferrer" target="_blank" style="color: rgb(0, 125, 188);">link</a><span style="color: rgb(0, 125, 188);"> </span></p><p><br></p>`
  }, [])
  const handleSave = () => {
    // const editor = editorRef.current.getEditor();
    let req = {
      companyId: location.state.id,
      resource: editorRef.current.value
    }
    saveEmployerResource(req).then(res => {
      if (res.type === "success") {
        setAlert({ type: res.type, message: "Saved successfully", open: true })
      } else {
        setAlert({ type: res.type, message: "Unable to save your changes.Please try again after sometime", open: true })
      }

    })
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    };
    setAlert({});
  };

  return (<>
    {alert.open && <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />}
    <Button variant="text" sx={{ mt: 1 }} startIcon={<ArrowBackIcon />} onClick={() => navigate("/companies")}>Back to list</Button>
    <Typography sx={{ fontSize: '32px', fontWeight: 400, display: 'flex', justifyContent: 'center' }}>Resource Editor</Typography>
    <br />
    <ReactQuill id="editor-container" ref={editorRef} theme="snow" placeholder="Start Typing...." value={value} onChange={setValue} modules={modules}
      formats={formats} />
    <br />
    <LoadingButton loading={false} sx={{ display: 'flex', justifyContent: 'center', margin: 'auto' }} loadingIndicator="Saving…" variant="contained" onClick={handleSave}>Save</LoadingButton>
  </>
  );
}
export default ResourceEditor;
import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

type CustomDropdownFieldProps = {
  label: string,
  name: string,
  options: Array<{ value: string, label: string }>
  width: number
  changeHandler: any,
  value: any,
  placeholder: string,
  error?: boolean,
  helperText?: any,
  type?: string,
  required?: boolean,
}

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: any) => option.label,
});
const CustomDropdownField = (props: CustomDropdownFieldProps) => {
  return (
    <FormControl error={props.error} required={props.required} sx={{ width: `${props.width}`, m: '5px 20px 10px 5px' }} variant="standard">
      <FormLabel sx={{ fontSize: '16px' }} >{props.label}</FormLabel>
      {props.type === "searchable" ?
        <Autocomplete
          // disablePortal
          options={props.options}
          filterOptions={filterOptions}
          // isOptionEqualToValue={(option, value) => option.value === value.value}
          value={props.value}
          onChange={(event, value) => {
            props.changeHandler({
              target: {
                value: value.value,
                name: props.name
              }
            })
          }}
          renderInput={(params: any) => <TextField {...params}
            sx={{ marginTop: '5px' }}
            size="small" error={props.error}
            helperText={props.helperText} />}
        />
        :
        <TextField
          sx={{ marginTop: '5px' }}
          name={props.name}
          size="small"
          select
          value={props.value}
          onChange={props.changeHandler}
          error={props.error}
          helperText={props.helperText}
          required={props.required}
        >
          {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      }
    </FormControl>



  );
}

export default CustomDropdownField

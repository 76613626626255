import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import Dialog from '@mui/material/Dialog';
import { Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';



type CustomConfirmProps = {
  setConfirmDialog:any,
    confirmDialog: {
        isOpen: boolean,
        title: string,
        body: string,
        onConfirm?: (event: Event | React.SyntheticEvent<any, Event>) => void,
    }
}


export default function ConfirmDialog(props:CustomConfirmProps) {

    const { confirmDialog, setConfirmDialog } = props;

    return (
        <Dialog open={confirmDialog.isOpen} className="Mui-customDialog" maxWidth="sm"

        >
            <DialogTitle sx={{padding:0}}>
                <div style={{ textAlign:'end',padding:'12px 18px 0px 0px'}}><IconButton className="MuiDialog-circularButton" aria-label="delete" color="primary" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                <HighlightOffRoundedIcon  />
</IconButton></div>
                <div style={{ textAlign:'start',padding:'0px 12px 10px 30px'}}><Typography variant="h3" >
                    {confirmDialog.title}
                </Typography> </div>
            
            </DialogTitle>
            <Divider className="MuiDivider-dialog"/>
            <DialogContent sx={{padding: '30px 30px'} }><Typography variant="h6" >{confirmDialog.body}</Typography></DialogContent>
            <DialogActions className="MuiDialog-Buttons" sx={{paddingBottom:'30px'}}>
              
                <Button sx={{marginRight:'25px'}} size="large" variant="outlined" autoFocus onClick={() => setConfirmDialog({...confirmDialog,isOpen:false}) } >
                            Cancel
                        </Button>
                <Button  size="large"  variant="contained" onClick={confirmDialog.onConfirm}>Yes, Continue</Button>
              
                    </DialogActions>
            </Dialog >)
}
import { useState, useEffect } from 'react';
import { getUserDetails } from '../../Utils/apiUtils';
import { useParams, useNavigate } from 'react-router-dom';
// import { CONST_SUCCESS } from '../../config/Constants';
import { setCookie, getCookie } from '../../Utils/helperUtils';

type user = {
   firstname?: string,
   email?: string,
   companyId?: string
}
export default function useUserDetails() {
   const [user, setUser] = useState<user>({});
   const [isLoading, setLoading] = useState(true);
   const { token } = useParams();
   const navigate = useNavigate();
   useEffect(() => {
      if (token) {
         getUserDetails(token).then((res: any) => {
            if (res.type === "success") {
               if (res.data.isExpired) {
                  navigate('/')
               } else {
                  setCookie("admintoken", "");
                  setUser(res.data);
                  setCookie("admintoken", token);
                  setCookie("adminuser", JSON.stringify(res.data));
                  navigate('/companies')
               }
            }
            setLoading(false);
         }).catch(err => {
            setLoading(false);
         })
      }
      else if (getCookie("adminuser")) {
         setUser(JSON.parse(getCookie("adminuser")));
         setLoading(false);
      }
      else {
         navigate('/');
      }
   }, []);
   return {
      user, setUser, isLoading, setLoading
   }
}
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../hooks/UserContext';
import useUserDetails from '../hooks/useUserDetails';
import CustomLoading from '../Elements/CustomLoading';
import * as _ from 'lodash';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

type Props = {
    component: any
}

const PrivateComponent: React.FC<Props> = (props) => {
    const { component: Component } = props;
    const navigate = useNavigate();
    const { user, setUser, isLoading, setLoading } = useUserDetails()

    useEffect(() => {
        if (_.isEmpty(user) && !isLoading) {
            navigate('/')
        }
    }, [isLoading, user])
    return (
        <>
            {isLoading && _.isEmpty(user) ? <CustomLoading />
                :
                <UserContext.Provider value={{ user, setUser }}>
                   <Header/>
                   <div className="content">
                      <Component/>
                   </div>
                <Footer/>
                </UserContext.Provider>
            }
        </>
    )


    // return null
}
export default PrivateComponent
import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

const CustomLoading = () => {
    return (
        <Box sx={{
            width: '100%',
            height: 'inherit',
            minHeight: "inherit",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}><CircularProgress /></Box>
    );
}
export default CustomLoading
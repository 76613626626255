import React from 'react';
import Footer from '../../assets/Footer.png';
import './Footer.css';

const Header = () => {

    return (
        <div className="footer">
            <img src={Footer} alt="footer" />
        </div>
    )
}
export default Header;
import React, { useState, useEffect } from 'react';
import Email from '../../assets/Email.png';
import Typography from '@mui/material/Typography';
import CustomLoading from '../Elements/CustomLoading';
// import { resendEmail } from '../../utils/APIUtils';
import { useNavigate, useLocation } from 'react-router-dom';
// import { CONST_SUCCESS } from '../../config/Constants';
import './ValidationSuccess.css'
// import CustomAlert from '../elements/CustomAlert';


const ValidationSuccess = () => {
  const location: any = useLocation();
  const { email, firstname } = location.state
  const [count, setCount] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [isResendEnabled, enableResend] = useState(false)
  const [alert, setAlert] = useState<any>({})
  let expirationTimer: any


  useEffect(() => {
    // let expirationTime = localStorage.getItem("expiryTime")
    // if (expirationTime) {
    //   setExipirationTimer(expirationTime)
    // }

    // return () => {
    //   localStorage.removeItem("expiryTime")
    //   clearInterval(expirationTimer)
    // };

  }, [])

  // const handleResend = () => {
  //   setLoading(true)
  //   resendEmail({ email, id, companyId }).then((res: any) => {
  //     if (res.type === CONST_SUCCESS) {
  //       setExipirationTimer(res.data.expirationTime)
  //       enableResend(false)
  //       setCount(count => ++count)
  //     } else {
  //       setAlert({ type: res.type, message: "We’ve had a technical error, please try again. If this problem continues, contact our customer support team", open: true })
  //     }
  //     setLoading(false)
  //   })

  // }

  // const setExipirationTimer = (exipartionTime: string) => {
  //   window.localStorage.setItem("expiryTime", exipartionTime)
  //   expirationTimer = setInterval(() => {
  //     if (new Date(exipartionTime) < new Date()) {
  //       enableResend(true)
  //       clearInterval(expirationTimer)
  //     }
  //   }, 60000)

  // }

  // const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setAlert({});
  // };

  return (
    <>
      {/* {alert.open && <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />} */}
      {isLoading ? <CustomLoading />
        :
        <div className="email-success">
          <div className="email-icon"><img src={Email} alt="email icon" className="email-iconimg" /></div>
          <br />
              <>
                <Typography className='validation-text' sx={{ fontSize: '32px', fontWeight: 300 }}>
                  Thanks {firstname || ""}, next step:<br /> Check your inbox.
                </Typography>
                <br />
                <Typography sx={{ fontSize: '16px' }}>
                  We just emailed a confirmation link to <strong>{email}</strong><br />
                  Click the link in that email to sign in and continue. This link will expire in 15 minutes.
                </Typography>
                <br/>
              </>
            <>
              <Typography sx={{ fontSize: '14px' }}>
                Didn’t get the confirmation? <br />
                Check your spam folder. After 15 minutes, you can resend the link if you did not receive it.
              </Typography>
              <br />
              <Typography sx={{ fontSize: '14px' }}>
                If you are on leave and unable to access your work email address, please contact <a style={{ color: '#037CB7' }} href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_SUPPORT_EMAIL}</a> to finish setting up your account.
              </Typography>
            </>
        </div>
      }
    </>
  );
}

export default ValidationSuccess;

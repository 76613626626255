import React from 'react';
import Router from './components/routing/Router';
import './App.css';
import { getCookie } from './Utils/helperUtils';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    dataLayer: any;
    Intercom: any;
  }
}


function App() {
  const { pathname } = useLocation();

  setTimeout(() => {
    const adminUser = JSON.parse(getCookie('adminuser') || '{}')
    let APP_ID = "l2sw55bi";
    const url = window.location.href;
    if (url.includes("https://admin.unumcarehub.com/")) {
      APP_ID = "l01znvs9";
    }


    window.Intercom('shutdown');
    if (Object.keys(adminUser).length) {
      window.Intercom('boot', {
        app_id: APP_ID,
        user_id: adminUser.token,
        name: `${adminUser.firstname} ${adminUser.lastname}`,
        email: adminUser.email,
        Path: pathname
      });
    } else {
      window.Intercom('boot', {
        app_id: APP_ID,
        Path: pathname
      });
    }
  }, 3500);


  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;

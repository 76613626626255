import React from 'react';
//import logo from '../../assets/Unum_Care_2022_RGB_logo.png';
import logo from '../../assets/Unum_Care-Hub_2023_RGB_Logo.svg';
import './Header.css'

const Header = () => {

    return (
        <div className="header">
            <img src={logo} alt="unum logo" />
        </div>
    )
}
export default Header;
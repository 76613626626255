import React, { useState, useContext, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../../assets/Unum_Care-Hub_2023_RGB_Logo.svg';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import { validateEmail } from '../../Utils/apiUtils';
import { useNavigate, useParams } from 'react-router-dom';
import WelcomeImage from '../../assets/WelcomeImage.png';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CustomTooltip from '../Elements/CustomTooltip';
import InfoIcon from '@mui/icons-material/Info';
import * as _ from 'lodash';
import FormLabel from '@mui/material/FormLabel';
import { Link } from '@mui/material';
import './Login.css';
import { emailIsValid } from '../../Utils/helperUtils';


const Login = () => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const { companyCode } = useParams();
  const [email, setEmail] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invalidEmail, setEmailInvalid] = useState(false)
  const [helperText, setHelperText] = useState("")

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (error) {
      setError(false)
    }
    setEmail(event.target.value);
  }
  const validateInput = () => {
    if (_.isEmpty(email)) {
      setEmailInvalid(true)
      setHelperText("Please enter an email")
    } else if (!emailIsValid(email)) {
      setEmailInvalid(true)
      setHelperText("Invalid email")
    } else {
      return true
    }
    return false
  }
  const verifyEmail = async () => {
    let isInputsValid = validateInput();
    if (isInputsValid) {
      setLoading(true)
      validateEmail({ companyCode: companyCode, email: email }).then((res: any) => {
        if (res.type === "success" && res.isValid) {
          if (res.success) {
            navigate("/validationSuccess", { state: { ...res, email: email } })
          } else {
            setError(true)
            setEmail("");
            setLoading(false)
          }
        } else {
          setError(true)
          setEmail("");
          setLoading(false)
        }
      })
    }
  }
  const getError = () => (
    <Alert sx={{ mt: '40px', padding: '20px' }} className="login-error" icon={false} severity="warning">
      <Typography>
        <p style={{ fontSize: '16px', fontWeight: 600, marginTop: 0 }}>Sorry, we aren’t able to find you. </p>
        <ul>
          <li>Try entering your work email address instead of a personal email address (eg. email@company.com)</li>
          <li>If you received an email about Care Hub, you can click the link in the email to try again.</li>
        </ul>
        <p style={{ fontSize: '14px', fontWeight: 600 }}>Know you should have access and still having trouble?</p>
        <a style={{ color: '#037CB7' }} href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_SUPPORT_EMAIL}</a>
      </Typography>
    </Alert>
  )

  return (
    <div className="login" style={{ display: 'flex', height: '100%', minHeight: '100vh' }}>
      <div className="login-background">
        <div className="login-image">
          <img src={WelcomeImage} alt="home illustration" />
        </div>
        <div className="login-text">
          <Typography sx={{ fontSize: '32px' }}>
            Let us be your guide
          </Typography>

          <br />
          <Typography sx={{ fontSize: '18px' }}>
            We'll connect you with the solutions that are the best fit for you as a caregiver, from a wide range of options available at no cost to you.
          </Typography>
        </div>
      </div>
      <div style={{ width: '100%' }}>
        <div className="login-content">
          <div className="login-logo"><img src={logo} alt="unum logo" /></div>
          {error && isMobileView && getError()}
          <br />
          <br />
          <div className="login-line"></div>
          <Typography sx={{ fontSize: '32px', fontWeight: 300 }}>
            Log in
          </Typography>

          <FormControl error={invalidEmail} sx={{ width: 1, margin: "30px 0px 20px 0px" }} variant="standard" >
            <div className="email-label">
              <div>
                <FormLabel sx={{ fontSize: '14px', fontWeight: 600 }} htmlFor="custom-textfield">
                  Email Address
                </FormLabel>
              </div>
              <CustomTooltip content="Use the work email addresses you provided at the time of registration."
              ><InfoIcon color="primary" sx={{ fontSize: 20 }} /></CustomTooltip>
            </div>

            <TextField size="small"
              id="custom-textfield"
              helperText={helperText}
              error={invalidEmail}
              name="email"
              value={email}
              onChange={(e: any) => { handleChange(e) }}
            />
          </FormControl>
          <LoadingButton fullWidth={true} className="login-btn" sx={{ mt: '20px' }} disabled={error || _.isEmpty(email)} loading={isLoading} loadingIndicator="Verifying..." variant="contained" onClick={verifyEmail}>Continue</LoadingButton>
          <br />
          {error && !isMobileView && getError()}
        </div>
      </div>
    </div>
  );
}

export default Login;

import React, {useEffect} from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';

type CustomAlertProps = {
  open: boolean,
  handleClose?: (event: Event | React.SyntheticEvent<any, Event>) => void,
  severity: "success" | "info" | "warning" | "error",
  message: string
}

const CustomAlert = (props: CustomAlertProps) => {
  useEffect(()=>{
    if(window.scrollY>=100){
      let alertElement=document.getElementById("alertbar")
      alertElement && alertElement.classList.add("stickyalert");
    }
    window.addEventListener("scroll", (event) => {
      let scroll = window.scrollY;
      let alertElement=document.getElementById("alertbar")
      if(alertElement){
        if (scroll && scroll >= 50 ){
          alertElement.classList.add("stickyalert");
        }
        else{
          alertElement.classList.remove("stickyalert")
        }
      }


  });
  
  },[])
 
  return (
    <Snackbar sx={{ maxWidth: '100%' }} id="alertbar" className="custom-alert" open={props.open} autoHideDuration={6000} onClose={props.handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert action={
        <IconButton
          onClick={props.handleClose}
        >
          <CancelIcon fontSize="inherit" />
        </IconButton>
      } onClose={props.handleClose} severity={props.severity} sx={{ width: '100%' }} iconMapping={{
        success: <CheckCircleIcon fontSize="large" />,
        error: <ErrorIcon fontSize="large" />
      }}>
        {props.message}
      </Alert>
    </Snackbar>

  );
}

export default CustomAlert;
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import { Typography, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button'
import { StyledTableCell, StyledTableRow } from './CompanyList';
import { IconButton } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CustomAlert from '../Elements/CustomAlert';
import CustomDialog from '../Elements/CustomDialog';
import { useNavigate, useLocation } from 'react-router-dom';
import { getEmployeeList, deleteEmployee, getCompanyDetails } from '../../Utils/apiUtils';
import './EmployeeList.css';


export interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
        event: React.MouseEvent<HTMLButtonElement>,
        newPage: number,
    ) => void;
};
// Custom Table- Pagination Actions Component
function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                className="MuiPaginationIcon-color"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                className="MuiPaginationIcon-color"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                className="MuiPaginationIcon-color"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                className="MuiPaginationIcon-color"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );


}
type employeeDetails = {
    id: string,
    firstname: string,
    lastname: string,
    email: string | undefined,
    contactNumber: string,
    state: string,
    companyId: string,
    companyName: string,
    companyCode: string,
    journeys: string,
    status: string,
    personalEmailCode: string | undefined
};

type CustomConfirmProps = {
    isOpen: boolean,
    title: string,
    body: string,
    onConfirm?: (event: Event | React.SyntheticEvent<any, Event>) => void,
};

const EmployeesList = () => {
    const [employeeList, setEmployeeList] = useState<Array<employeeDetails>>([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isEmployeesLoading, setEmployeesLoading] = useState(false);
    const [alert, setAlert] = useState<any>({});
    const [confirmDialog, setConfirmDialog] = useState<CustomConfirmProps>({
        isOpen: false,
        title: '',
        body: ''
    });
    const navigate = useNavigate();
    const location: any = useLocation();

    useEffect(() => {
        setEmployeesLoading(true);
        getEmployeeList(location.state.id).then((res: any) => {
            setEmployeeList(res);
            setEmployeesLoading(false);
        })
    }, [location.state.id]);



    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (company: string, id: string) => {
        const request = {
            companyId: company,
            employeeId: id,
        };
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });

        setEmployeesLoading(true);
        deleteEmployee(request).then(res => {
            setAlert({ type: res.type, message: res.message, open: true })
            setEmployeesLoading(true);
            getEmployeeList(location.state.id).then((res: any) => {
                setEmployeeList(res);
                setEmployeesLoading(false);
            });
            setEmployeesLoading(false);
        });
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({});
    };

    return (
        <Paper elevation={2} sx={{ m: 1, mr: 1, width: '91.7%', padding: '24px 32px' }}>
            {alert.open && <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />}
            <div className="employeelist-header">
                <div>
                    <Typography variant="h5">
                        Employees
                    </Typography>
                    <Typography sx={{ fontSize: '14px', mb: 2 }} >
                        Employees currently enrolled in the platform
                    </Typography>
                </div>
                <div>
                    <Button sx={{ float: 'right' }} variant="text" size="small" startIcon={<AddIcon />} onClick={() => navigate('/EditEmployee', { state: { type: 'add', companyId: location.state.id } })}>Add New Employee</Button>
                </div>
            </div>
            {isEmployeesLoading ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}><CircularProgress /></Box>
                : <>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>First Name</StyledTableCell>
                                    <StyledTableCell>Last Name</StyledTableCell>
                                    <StyledTableCell>Username</StyledTableCell>
                                    <StyledTableCell>Journeys</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0
                                    ? employeeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : employeeList
                                ).map((row: employeeDetails, index: number) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.firstname}
                                        </StyledTableCell>
                                        <StyledTableCell>{row.lastname}</StyledTableCell>
                                        <StyledTableCell>{row.email || row.personalEmailCode}</StyledTableCell>
                                        <StyledTableCell>{row.journeys.replace(/[,]/g, ', ')}</StyledTableCell>
                                        <StyledTableCell sx={{ width: "18%", padding: 0 }}>
                                            <Button size="small" sx={{ width: "40px", minWidth: "0" }} startIcon={<EditIcon />} onClick={() => navigate('/EditEmployee', { state: { type: 'edit', companyId: row.companyId, id: row.id } })}></Button>
                                            <Button sx={{ color: "#B30303", width: "40px", minWidth: "0" }} color="error" aria-controls="ringtone-menu" variant="text" size="small" startIcon={<DeleteIcon />} onClick={() => { setConfirmDialog({ isOpen: true, title: 'Are you sure?', body: 'This action cannot be undone. Are you sure you want to delete this employee?', onConfirm: () => { handleDelete(row.companyId, row.id) } }) }}></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <CustomDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog}></CustomDialog>
                    <TablePagination
                        component="div"
                        className="table-pagination"
                        rowsPerPageOptions={[5, 10, 15]}
                        count={employeeList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                    />
                </>}

        </Paper>

    );
};
export default EmployeesList;


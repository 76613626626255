import React, { useState, useEffect } from 'react';
import CustomTextField from '../Elements/CustomTextField';
import CustomDropdownField from '../Elements/CustomDropdownField';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { FormGroup, Button, FormControlLabel, Switch, FormLabel, Checkbox, Radio } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as _ from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { getPartners, getStates, saveCompanyDetails, updateCompanyDetails, getCompanyDetails } from '../../Utils/apiUtils';
import { statusOptions, validationSchema } from '../../Utils/Constants';
import CustomAlert from '../Elements/CustomAlert';
import Box from '@mui/system/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { emailIsValid } from '../../Utils/helperUtils';

type FormData = {
    [key: string]: string | number | object | boolean | undefined,
    company: string,
    status: string,
    contactDetails: Array<{ name: string, email: string, contactnumber: string }>,
    domains: Array<string>,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    companySize: string,
    journeys: any,
    companyCode?: string,
    companyUrl?: string,
    integrations?: Array<string>,
    userCreationByCompanyCode: boolean,
    userCreationByEmailDomain: boolean
}
const initialFormData: FormData = {
    company: "",
    status: "Active",
    contactDetails: [{ name: "", email: "", contactnumber: "" }],
    domains: [""],
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    companySize: "",
    journeys: {
        caregiving: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        },
        parenting: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        },
        wellness: {
            enabled: false,
            partners: {
                partner: [],
                configuration: []
            }
        }
    },
    integrations: [],
    partnerRanking: {},
    userCreationByCompanyCode: false,
    userCreationByEmailDomain: true
}

type configDetails = { partnername: string, partnerconfig: Array<string> }

const WebForm = () => {
    const [data, setData] = useState<FormData>(_.cloneDeep(initialFormData));
    const [errors, setErrors] = useState<any>({});
    const [journeyFlag, setJourneyFlag] = useState<boolean>(false);
    const [partners, setPartners] = useState<any>({});
    const [stateOptions, setStates] = useState<any>([]);
    const [alert, setAlert] = useState<any>({});
    const [disabledPartners, setDisabledPartners] = useState<any>([]);
    const [disabledJourneys, setDisabledJourneys] = useState<any>([])
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [existingCompanyDetails, setExistingCompanyDetails] = useState<FormData>(_.cloneDeep(initialFormData));
    const navigate = useNavigate();
    const location: any = useLocation();

    useEffect(() => {
        getPartners().then((res: any) => setPartners(res))
        getStates().then((res: any) => setStates(res))
    }, [])

    //Load company details when in edit
    useEffect(() => {
        if (location.state) {
            getCompanyDetails(location.state.data.id).then((res: any) => {
                if (!res.domains) res.domains = ['']
                setData(res)
                setExistingCompanyDetails(JSON.parse(JSON.stringify(res)))
                setJourneyFlag(true)
                setBusinessRuleForPartners(res.journeys)
                setBusinessRuleForJourneys(res.journeys, res.integrations)
                setEditMode(true)
            })
        }
    }, [location.state])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [event.target.name]: event.target.value });
    }

    const handleContactChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setData((data: FormData) => {
            let contactDetails = data.contactDetails || []
            contactDetails[index] = { ...contactDetails[index], [event.target.name]: event.target.value }
            return { ...data, contactDetails }
        });
    }

    const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setData((data: FormData) => {
            let domains = data.domains || []
            domains[index] = event.target.value
            return { ...data, domains }
        });
    }

    const addNewField = (type: string) => {
        setData((data: FormData) => {
            if (type === "contactDetails") {
                let field = data["contactDetails"] || []
                field.push({ name: "", email: "", contactnumber: "" })
                return { ...data, [type]: field }
            } else if (type === "domains") {
                let field = data["domains"] || []
                field.push("")
                return { ...data, [type]: field }
            }
            return { ...data }
        });
    }

    const removeField = (type: string, index: number) => {
        setData((data: FormData) => {
            if (type === "contactDetails") {
                let field = data["contactDetails"] || []
                field.splice(index, 1);
                return { ...data, [type]: field }
            } else if (type === "domains") {
                let field = data["domains"] || []
                field.splice(index, 1);
                return { ...data, [type]: field }
            }
            return { ...data }
        });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        let isInputsValid = validateForm()
        if (isInputsValid) {
            setLoading(true)
            if (editMode) {
                updateCompanyDetails(data).then(res => {
                    setExistingCompanyDetails(data)
                    setErrors({})
                    setAlert({ type: res.type, message: res.message, open: true })
                    setLoading(false)
                    if (res.type === "success") {
                        setTimeout(() => navigate('/companies'), 1000)
                    }
                })
            } else {
                saveCompanyDetails(data).then(res => {
                    if (res.type === "success") {
                        setData(initialFormData)
                        setDisabledPartners([])
                        setErrors({})
                        setTimeout(() => navigate('/companies'), 1000)
                    }
                    setAlert({ type: res.type, message: res.message, open: true })
                    setLoading(false)
                })
            }
        }
    }

    const validateForm = () => {
        let isValid = true
        if (data.userCreationByEmailDomain) validationSchema.domains.required = true
        setErrors((errors: any) => {
            errors = {}
            for (var field in data) {
                if (field === "contactDetails") {
                    let contactErrors: any = {}
                    contactErrors[field] = []
                    data[field].forEach((each, index) => {
                        contactErrors[field].push({})
                        if (_.isEmpty(each.name)) contactErrors[field][index].name = validationSchema[field].helperText.name
                        if (_.isEmpty(each.email)) {
                            contactErrors[field][index].email = validationSchema[field].helperText.email
                        } else if (!emailIsValid(each.email)) {
                            contactErrors[field][index].email = validationSchema[field].helperText.emailInvalid
                        }
                    })
                    let inValidContacts = contactErrors[field].filter((each: any) => !_.isEmpty(each))
                    if (inValidContacts.length) errors = { ...errors, ...contactErrors }
                }
                else if (data.userCreationByEmailDomain && field === "domains") {
                    let domainErrors: any = {}
                    domainErrors[field] = []
                    data[field].forEach((each, index) => {
                        domainErrors[field].push("")
                        if (_.isEmpty(each)) domainErrors[field][index] = validationSchema[field].helperText

                    })
                    let inValidDomains = domainErrors[field].filter((each: any) => !_.isEmpty(each))
                    if (inValidDomains.length) errors = { ...errors, ...domainErrors }
                }
                else if (field === "journeys") {
                    let selectedJourneys = Object.entries(data[field]).filter((journey: any) => journey[1].enabled)
                    if (selectedJourneys.length === 0) {
                        errors[field] = validationSchema[field].helperText.journey
                    } else {
                        selectedJourneys.forEach((journey: any) => {
                            if (journey[1].partners.partner.length === 0 && journey[0] !== "wellness") {
                                errors["partners"] = validationSchema[field].helperText.partners
                            } else {
                                journey[1].partners.configuration && journey[1].partners.configuration.forEach((config: any) => {
                                    if (config.partnerconfig.length === 0) {
                                        errors["configuration"] = validationSchema[field].helperText.configuration;
                                    }
                                })
                            }
                        })
                    }
                }
                else if (validationSchema[field] && validationSchema[field].required && _.isEmpty(data[field])) {
                    errors[field] = validationSchema[field].helperText
                } else if (editMode && field === "companyCode" && _.isEmpty(data[field])) {
                    errors[field] = validationSchema[field].helperText
                }
            }
            isValid = _.isEmpty(errors)
            return { ...errors }
        })
        return isValid
    }

    const handleIntegrationsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData((data: FormData) => {
            let integrations = data.integrations || []
            if (integrations.includes(event.target.name)) {
                integrations.splice(integrations.indexOf(event.target.name), 1)
            } else {
                integrations.push(event.target.name)
            }
            setBusinessRuleForJourneys(data.journeys, integrations)
            return { ...data, integrations }
        });
    }

    const handleJourneyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData((data: FormData) => {

            let journeys = data.journeys || {}
            let partnerRanking = data.partnerRanking || {}
            let selectedJourneys = []
            journeys[event.target.name].enabled = !journeys[event.target.name].enabled
            if (!journeys[event.target.name].enabled) {
                let partners = [...journeys[event.target.name].partners.partner]
                journeys[event.target.name].partners.partner = [];
                journeys[event.target.name].partners.configuration = [];

            }
            selectedJourneys = Object.entries(journeys).filter(([key, value]: any) => value.enabled)
            if (selectedJourneys.length > 0) setJourneyFlag(true)
            else setJourneyFlag(false)

            setBusinessRuleForJourneys(journeys, data.integrations)
            setBusinessRuleForPartners(journeys)



            return { ...data, journeys, partnerRanking }
        });
    }
    const handlePartnerChange = (event: React.ChangeEvent<HTMLInputElement>, journey: string) => {
        setData((data: FormData) => {
            let journeys = data.journeys || {};
            let partnerRanking = data.partnerRanking || {}
            const config = data.journeys[journey].partners.configuration?.find(((config: configDetails) => config.partnername === event.target.name)) || {};
            if (journeys[journey].partners.partner.includes(event.target.name)) {
                journeys[journey].partners.partner = journeys[journey].partners.partner.filter((value: string) => value !== event.target.name);
                data.journeys[journey].partners.configuration?.splice(data.journeys[journey].partners.configuration.indexOf(config), 1);
            } else {
                journeys[journey].partners.partner.push(event.target.name);
                !journeys[journey].partners.configuration ? journeys[journey].partners.configuration = [{
                    partnername: event.target.name,
                    partnerconfig: []
                }] : journeys[journey].partners.configuration.push({
                    partnername: event.target.name,
                    partnerconfig: []
                });
            }
            setBusinessRuleForPartners(journeys)
            return { ...data, journeys, partnerRanking }

        });
    }

    const handleConfigurationChange = (event: React.ChangeEvent<HTMLInputElement>, journey: string, partner: string) => {
        setData((data: FormData) => {
            let journeys = data.journeys || {}
            const config = data.journeys[journey].partners.configuration?.find(((config: configDetails) => config.partnername === partner)) || {};

            if (config.partnername === partner) {
                if (config.partnerconfig.includes(event.target.name)) {
                    config.partnerconfig = config.partnerconfig.filter((value: string) => value !== event.target.name);
                } else { config.partnerconfig = [event.target.name] }
            }
            return { ...data, journeys }
        })
    }

    const handleUserRegistrationConfigurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setData((data: FormData) => {

            let userCreationByCompanyCode: boolean = false
            let userCreationByEmailDomain: boolean = false
            let domains: Array<string> = data.domains || [""]

            if (event.target.name === 'Company Code Validation') {
                userCreationByCompanyCode = event.target.checked
                userCreationByEmailDomain = !event.target.checked
            }
            if (event.target.name === 'Domain Validation') {
                userCreationByEmailDomain = event.target.checked
                userCreationByCompanyCode = !event.target.checked
            }

            return { ...data, domains, userCreationByCompanyCode, userCreationByEmailDomain }
        })
    }

    //Business rule to enable either Ianacare or Cariloop but not both. This will be added in the backend later
    const setBusinessRuleForPartners = (journeys: any) => {

        if (journeys["caregiving"].partners.partner.includes("Ianacare")) {
            setDisabledPartners((data: Array<string>) => [...data, "Cariloop Digital"])
            setDisabledPartners((data: Array<string>) => [...data, "Cariloop Concierge"])
        } else if (journeys["caregiving"].partners.partner.includes("Cariloop Digital") || journeys["caregiving"].partners.partner.includes("Cariloop Concierge")) {
            setDisabledPartners((data: Array<string>) => [...data, "Ianacare"])

            if (journeys["caregiving"].partners.partner.includes("Cariloop Concierge")) {
                setDisabledPartners((data: Array<string>) => [...data, "Cariloop Digital"])
            } else if (journeys["caregiving"].partners.partner.includes("Cariloop Digital")) {
                setDisabledPartners((data: Array<string>) => [...data, "Cariloop Concierge"])
            }

        } else {
            setDisabledPartners([])
        }


        if (journeys["parenting"].enabled) {
            setDisabledPartners((data: Array<string>) => [...data])
        }
    }
    const setBusinessRuleForJourneys = (journeys: any, intergrations: any) => {
        if (journeys["wellness"].enabled) {
            setDisabledJourneys((data: Array<string>) => [...data, "caregiving", "parenting", "Unum Behavioral Health"])
        }
        else if (intergrations.includes("Unum Behavioral Health") || journeys["caregiving"].enabled || journeys["parenting"].enabled) {
            setDisabledJourneys((data: Array<string>) => [...data, "wellness"])
        }
        else {
            setDisabledJourneys([])
        }
    }
    const resetForm = () => {
        if (editMode) {
            setData(existingCompanyDetails)
            setJourneyFlag(true)
        } else {
            setData(initialFormData)
            setDisabledPartners([])
            setErrors({})
        }
    }
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert({});
    }
    const caregivingPartnersName = (name: string) => {
        if (name === 'Cariloop Concierge') return "Cariloop - Concierge"
        if (name === 'Cariloop Digital') return "Cariloop - Digital"
        return name
    }

    return (
        <>
            {alert.open && <CustomAlert open={alert.open} message={alert.message} severity={alert.type} handleClose={handleClose} />}
            <Button variant="text" sx={{ mt: 1 }} startIcon={<ArrowBackIcon />} onClick={() => navigate("/companies")}>Back to list</Button>
            <Box sx={{ backgroundColor: 'white', padding: '20px 20px 40px 32px', borderRadius: '5px' }}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <Typography color="text.secondary" variant="h3" sx={{ m: "40px 0" }}>
                        Company Details
                    </Typography>
                    <div style={{ margin: '10px' }}>

                        <CustomTextField error={errors.company ? true : false} helperText={errors.company ? errors.company : ""} label="Company Name" name="company" width={0.6} required={true} value={data.company} changeHandler={handleChange} />
                        <CustomDropdownField label="Status" name="status" placeholder="Status" options={statusOptions} value={data.status} width={0.3} changeHandler={handleChange} />

                        <Divider variant="fullWidth" className="MuiDivider-webform" />

                        <Typography color="text.secondary" variant="h5" sx={{ mb: 2 }}>
                            Contact Information
                        </Typography>
                        <div >
                            {data.contactDetails && data.contactDetails.map((contact, index) => {
                                let contactError = (errors.contactDetails && errors.contactDetails[index]) || {}
                                return (
                                    <React.Fragment key={"contact" + index}>
                                        <CustomTextField error={contactError.name ? true : false} helperText={contactError.name ? contactError.name : ""} label="Contact Name" name="name" width={0.27} required={true} value={contact.name} changeHandler={e => handleContactChange(e, index)} />
                                        <CustomTextField error={contactError.email ? true : false} helperText={contactError.email ? contactError.email : ""} label="Contact Email Address" name="email" width={0.27} required={true} value={contact.email} changeHandler={e => handleContactChange(e, index)} />
                                        <CustomTextField type="phonenumber" label="Phone Number" name="contactnumber" width={0.27} required={false} value={contact.contactnumber} changeHandler={e => handleContactChange(e, index)} />
                                        {index !== 0 && <Button color="error" startIcon={<DeleteIcon />} variant="text" size="small" sx={{ mt: 4, fontSize: '16px' }} onClick={() => removeField("contactDetails", index)}>Remove</Button>}
                                    </React.Fragment>
                                )
                            })}
                            <br />
                            <Button variant="text" startIcon={<AddIcon />} disabled={data.contactDetails.length >= 10} sx={{ fontSize: '16px' }} onClick={() => addNewField("contactDetails")}>Add More</Button>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            {
                                data.domains && data.domains.map((domain, index) => {
                                    const domainError = (errors.domains && errors.domains[index]) || ""
                                    return (
                                        <React.Fragment key={"domain" + index} >
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                <CustomTextField error={domainError.length > 0 ? true : false}
                                                    helperlabel={index === 0 ? "Include '@' before each domain (eg. @acme.com). You may add multiple by selecting 'add more'" : ""}
                                                    helperText={domainError.length > 0 ? domainError : ""} hidelabel={index === 0 ? false : true}
                                                    label="Company Email Domain(s)" name="domains" width={0.85}
                                                    required={data.userCreationByEmailDomain} value={domain} changeHandler={e => handleDomainChange(e, index)} />
                                                {index !== 0 && <Button color="error" startIcon={<DeleteIcon />} variant="text" sx={{ mt: 2, fontSize: '16px' }} onClick={() => removeField("domains", index)}>Remove</Button>}
                                                {index === 0 && <Button variant="text" sx={{ mt: 5, fontSize: '16px' }} className="MuiAddMore-button" startIcon={<AddIcon />} onClick={() => addNewField("domains")}>Add More</Button>}
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>


                        <CustomTextField label="Company Address Line 1" name="address1" width={0.95} required={false} value={data.address1} changeHandler={handleChange} />
                        <CustomTextField label="Company Address Line 2" name="address2" width={0.95} required={false} value={data.address2} changeHandler={handleChange} />
                        <CustomTextField label="City" name="city" width={0.3} required={false} value={data.city} changeHandler={handleChange} />
                        <CustomDropdownField type="searchable" label="State" name="state" placeholder="Select a State" options={stateOptions} value={data.state} width={0.3} changeHandler={handleChange} />
                        <CustomTextField label="Zip Code" name="zipcode" width={0.3} required={false} value={data.zipcode} changeHandler={handleChange} />
                        <CustomTextField helperlabel="Number of Employees" type="number" label="Company Size" name="companySize" width={0.6} required={false} value={data.companySize} changeHandler={handleChange} />
                    </div>
                    <div style={{ margin: '15px' }}>
                        <Divider variant="fullWidth" className="MuiDivider-webform" />
                    </div>
                    <div style={{ margin: '15px' }} >
                        <FormLabel sx={{ fontSize: '20px', mt: '24px' }} component="legend">Account Creation</FormLabel>
                        <Typography style={{ fontSize: '13px', fontWeight: 400, lineHeight: '24px', marginBottom: '10px' }}>
                            Select the primary field for account creation based on the company's preferences
                        </Typography>
                        <FormControlLabel control={<Radio checked={data.userCreationByEmailDomain} name="Domain Validation" onChange={handleUserRegistrationConfigurationChange} sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '24px!important',
                            },
                        }} />} label="Email Domain" />
                        <FormControlLabel control={<Radio checked={data.userCreationByCompanyCode} name="Company Code Validation" onChange={handleUserRegistrationConfigurationChange} sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '24px!important',
                            },
                        }} />} label="Company Code" />
                    </div>
                    <div style={{ margin: '15px' }}>
                        <Divider variant="fullWidth" className="MuiDivider-webform" />
                    </div>
                    <div style={{ margin: '15px' }} >
                        <FormLabel sx={{ fontSize: '20px', mt: '24px' }} component="legend">Integrations</FormLabel>
                        <FormControlLabel control={<Switch disabled={disabledJourneys.includes("Unum Behavioral Health")} checked={data.integrations?.includes("Unum Behavioral Health")} name="Unum Behavioral Health" onChange={handleIntegrationsChange} />} label="Unum Behavioral Health" />
                        <FormControlLabel control={<Switch checked={data.integrations?.includes("Unum Total Leave")} name="Unum Total Leave" onChange={handleIntegrationsChange} />} label="Unum Total Leave" />
                        <FormControlLabel control={<Switch checked={data.integrations?.includes("Unum Leave Logic")} name="Unum Leave Logic" onChange={handleIntegrationsChange} />} label="Unum Leave Logic" />
                    </div>
                    <div style={{ margin: '15px' }}>
                        <Divider variant="fullWidth" className="MuiDivider-webform" />
                    </div>
                    {editMode && <CustomTextField label="Unique Company URL" disabled={true} name="companyUrl" width={0.4} required={false} value={data.companyUrl} changeHandler={handleChange} />}
                    <div style={{ margin: '15px' }} >
                        <FormLabel required={true} sx={{ fontSize: '20px', mt: '24px' }} component="legend">Journey Settings</FormLabel>
                        <FormControlLabel control={<Switch disabled={disabledJourneys.includes("wellness")} checked={data.journeys["wellness"].enabled} name="wellness" onChange={handleJourneyChange} />} label="Wellness Only (no partners)" />
                        <FormControlLabel control={<Switch disabled={disabledJourneys.includes("caregiving")} checked={data.journeys["caregiving"].enabled} name="caregiving" onChange={handleJourneyChange} />} label="Caregiving" />
                        <FormControlLabel control={<Switch disabled={disabledJourneys.includes("parenting")} checked={data.journeys["parenting"].enabled} name="parenting" onChange={handleJourneyChange} />} label="Parenting" />
                        <Typography color="red">
                            {errors.journeys}
                        </Typography>
                    </div>
                    {editMode && <CustomTextField error={errors.companyCode ? true : false} helperText={errors.companyCode ? errors.companyCode : ""} label="Company Code(For all partners)" name="companyCode" width={0.4} required={false} value={data.companyCode} changeHandler={handleChange} />}
                    <div
                        style={{ margin: '15px' }} >
                        <FormLabel required={true} sx={{ fontSize: '20px', mt: 3 }} component="legend">Partner Settings</FormLabel>
                        {Object.keys(data.journeys).map((journey) => {
                            if (data.journeys[journey].enabled === true) {
                                if (journey === "caregiving") {
                                    return (
                                        <FormGroup row={false} sx={{ m: '32px 5px 25px 0px' }} key={journey}>
                                            <FormLabel component="legend">Caregiving Partners</FormLabel>
                                            {!_.isEmpty(partners) && partners[journey].map((partner: string) => {
                                                const config = data.journeys[journey].partners.configuration?.find(((config: configDetails) => config.partnername === partner)) || {};
                                                return <div className="partner-switch" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '30px' }}>
                                                    <FormControlLabel key={partner} control={<Switch disabled={disabledPartners.includes(partner)} checked={data.journeys[journey].partners.partner.includes(partner)} name={partner}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePartnerChange(e, journey)} />} label={caregivingPartnersName(partner)} />
                                                    {
                                                        data.journeys[journey].partners.partner.includes(partner) ? (
                                                            <div style={{ display: 'flex', width: '70%', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end" }}>
                                                                <Typography sx={{ mr: 3 }}>Configuration:</Typography>
                                                                {
                                                                    (partner === 'Cariloop Digital' || partner === 'Cariloop Concierge') && (
                                                                        <div>
                                                                            <FormControlLabel
                                                                                key={'code'}
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={disabledPartners.includes(partner)}
                                                                                        checked={config.partnername === partner && config.partnerconfig.includes("CompanyCode")}
                                                                                        name={"CompanyCode"}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)}
                                                                                        style={{ transform: "scale(1.5)" }}
                                                                                    />
                                                                                }
                                                                                label={"Company Code"}
                                                                            />
                                                                            <FormControlLabel
                                                                                key={'urbansitter'}
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={disabledPartners.includes(partner)}
                                                                                        checked={config.partnername === partner && config.partnerconfig.includes("Urbansitter")}
                                                                                        style={{ transform: "scale(1.5)" }}
                                                                                        name={"Urbansitter"}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)}
                                                                                    />
                                                                                }
                                                                                label={"Urbansitter"}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    (partner !== 'Cariloop Digital' && partner !== 'Cariloop Concierge') && (
                                                                        <div>
                                                                            <FormControlLabel
                                                                                key={'code'}
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={disabledPartners.includes(partner)}
                                                                                        checked={config.partnername === partner && config.partnerconfig.includes("CompanyCode")}
                                                                                        name={"CompanyCode"}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)}
                                                                                        style={{ transform: "scale(1.5)" }}
                                                                                    />
                                                                                }
                                                                                label={"Company Code"}
                                                                            />
                                                                            <FormControlLabel
                                                                                key={'email'}
                                                                                control={
                                                                                    <Checkbox
                                                                                        disabled={disabledPartners.includes(partner)}
                                                                                        checked={config.partnername === partner && config.partnerconfig.includes("EmailDomain")}
                                                                                        style={{ transform: "scale(1.5)" }}
                                                                                        name={"EmailDomain"}
                                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)}
                                                                                    />
                                                                                }
                                                                                label={"Email Domain"}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        ) : ""
                                                    }
                                                </div>
                                            })}
                                        </FormGroup>
                                    )
                                } else if (journey === "parenting") {
                                    return (
                                        <FormGroup row={false} sx={{ m: '32px 5px 25px 0px' }} key={journey}>
                                            <FormLabel sx={{ mb: '4px' }} component="legend">Parenting Partners</FormLabel>
                                            {!_.isEmpty(partners) && partners[journey].map((partner: string) => {
                                                const config = data.journeys[journey].partners.configuration?.find(((config: configDetails) => config.partnername === partner)) || {};
                                                return <div className="partner-switch" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '30px' }}>
                                                    <FormControlLabel key={partner} control={<Switch disabled={disabledPartners.includes(partner)} checked={data.journeys[journey].partners.partner.includes(partner)} name={partner}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePartnerChange(e, journey)} />} label={partner} />
                                                    {data.journeys[journey].partners.partner.includes(partner) ? (<div style={{ display: 'flex', width: '70%', flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end" }}>
                                                        <Typography sx={{ mr: 3 }}>Configuration:</Typography>
                                                        <FormControlLabel key={'email'} control={<Checkbox disabled={disabledPartners.includes(partner)} checked={config.partnername === partner && config.partnerconfig.includes("CompanyCode")} style={{ transform: "scale(1.5)" }} name={"CompanyCode"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)} />} label={"Company Code"} />
                                                        <FormControlLabel key={'code'} control={<Checkbox disabled={disabledPartners.includes(partner)} checked={config.partnername === partner && config.partnerconfig.includes("EmailDomain")} style={{ transform: "scale(1.5)" }} name={"EmailDomain"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleConfigurationChange(e, journey, partner)} />} label={"Email Domain"} /></div>) : ""}
                                                </div>
                                            })}
                                        </FormGroup>
                                    )
                                } else return null
                            } else return null
                        })}
                        {!journeyFlag && <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                            Selecting a journey will open partner settings
                        </Typography>}
                        {errors.partners && (<Typography sx={{ color: "#B30303 !important", mt: 2 }}>
                            {errors.partners}
                        </Typography>)}
                        {errors.journeys && (<Typography sx={{ color: "#B30303 !important", mt: 2 }}>
                            {errors.journeys}
                        </Typography>)}
                        {errors.configuration && (<Typography sx={{ color: "#B30303 !important", mt: 2 }}>
                            {errors.configuration}
                        </Typography>)}
                        {errors.partnerRanking && (<Typography sx={{ color: "#B30303 !important", mt: 2 }}>
                            {errors.partnerRanking}
                        </Typography>)}

                    </div>
                    <div style={{ display: 'flex', marginTop: '10px', alignItems: 'center', justifyContent: "space-between" }}>
                        <div>
                            <Typography sx={{ fontSize: '12px', fontWeight: 'bold', mt: 3, ml: 1 }}>
                                There are required fields in this form marked <span style={{ color: '#B30303' }}>*</span>
                            </Typography>
                        </div>
                        <div>
                            <Button variant="outlined" style={{ marginRight: '15px', }} onClick={resetForm}>Cancel</Button>
                            <LoadingButton loading={isLoading} loadingIndicator="Saving…" variant="contained" style={{ marginRight: '25px' }} type="submit">Save Company Details</LoadingButton>
                        </div>
                    </div>
                </form >
            </Box >
        </>
    )
}

export default WebForm
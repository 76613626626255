import React from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';


type Props = {
    component: any
}

const ProtectedComponent: React.FC<Props> = (props) => {
    const { component: Component } = props;
 
    return (
        <div style={{ backgroundColor: '#F6F8FA' }}>
            <Header />
            <div style={{ height: 'auto', minHeight: '75vh' }}>
                <div style={{ padding: '5% 12%' }}>
                    <Component />
                </div>
            </div>
            <Footer />
        </div>
    )

}
export default ProtectedComponent
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltipBase = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
    left: "20px !important"
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    fontSize: '14px',
    padding: '16px',
  },
  [`& `]: {
    inset: "auto auto 0 -20px !important "
  },
}));

type CustomtooltipProps = {
  content: string,
  children: any
}

const CustomTooltip = (props: CustomtooltipProps) => {
  return (
    <CustomTooltipBase title={props.content} placement="top">
      {props.children}
    </CustomTooltipBase>
  );
}
export default CustomTooltip
import React from "react";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import { PatternFormat, NumericFormat } from 'react-number-format';
import './globalStyles.css';
import TextField from "@mui/material/TextField";

type CustomTextFieldProps = {
  label: string,
  name: string,
  width: number,
  required: boolean
  changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
  value?: string,
  error?: boolean,
  helperText?: any,
  hidelabel?: boolean,
  type?: string,
  helperlabel?: string,
  disabled?: boolean
}

const CustomTextField = (props: CustomTextFieldProps) => {

  return (
    <FormControl error={props.error} sx={{ width: `${props.width}`, m: '5px 20px 10px 5px' }} variant="standard" required={props.required}>
          {!props.hidelabel && <FormLabel sx={{ fontSize: '16px' }} required={props.required} htmlFor="custom-textfield">
        {props.label}
      </FormLabel>}

          {props.helperlabel && (<><FormLabel required={false} sx={{ fontSize: '13px' }}>
        {props.helperlabel}
      </FormLabel></>)
          }
      {props.type === "phonenumber" ?
              <PatternFormat
          sx={{ marginTop: '5px' } }
          customInput={TextField}
          onChange={props.changeHandler}
          size="small"
          helperText={props.helperText}
          error={props.error}
          name={props.name}
          required={props.required}
          value={props.value}
          format="###-###-####"
          allowEmptyFormatting={true}
          mask=" "
        />
        :
        props.type === "number" ?
                  <NumericFormat
            sx={{ marginTop: '5px' }}
            allowLeadingZeros={false}
            thousandSeparator=","
            allowNegative={false}
            customInput={TextField}
            size="small"
            helperText={props.helperText}
            error={props.error}
            name={props.name}
            required={props.required}
            value={props.value}
            onChange={props.changeHandler}
          />
                  :
           <TextField size="small"
            sx={{ marginTop: '5px' }}
            id="custom-textfield"
            helperText={props.helperText}
            error={props.error}
            name={props.name}
            required={props.required}
            value={props.value}
            onChange={props.changeHandler}
            disabled={props.disabled}
          />
      }
    </FormControl>
  );
}

export default CustomTextField